<template>
  <div>
    <b-modal
      id="modal-edit-team"
      ref="modal-edit-team"
      no-close-on-backdrop
      no-close-on-esc
      footer-class="flex-nowrap"
      centered
    >
      <template #modal-header>
        <h3>{{ $t('modals.editTeam.title') }}</h3>
      </template>

      <template #default>
        <modal-choose-team-name-and-description
          :teamName="teamName"
          :teamDescription="teamDescription"
          @update-fields="updateFields"
          @enter-pressed="editTeam"
        ></modal-choose-team-name-and-description>
      </template>

      <template #modal-footer>
        <b-button
          class="p-3 border-right-light-grey"
          block
          @click="cancelModal"
        >
          {{ $t('buttons.cancel') }}
        </b-button>
        <b-button
          :disabled="isDisabled"
          variant="primary"
          class="p-3"
          block
          @click="editTeam"
        >
          {{ $t('modals.editTeam.change') }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: 'ModalEditTeam',
  components: {
    ModalChooseTeamNameAndDescription: () => import('@/components/modals/ModalChooseTeamNameAndDescription.vue'),
  },
  props: ['team'],
  data: () => ({
    teamName: null,
    teamDescription: null,
  }),
  created() {
    if (this.team) {
      this.teamName = this.team.teamName;
      this.teamDescription = this.team.teamDescription;
    }
  },
  mounted() {
    this.$refs['modal-edit-team'].show();
  },
  computed: {
    isDisabled() {
      // if teamName is empty or undefined
      if (!this.teamName) {
        return true;
      }
      if (
        this.teamName === this.team.teamName
        && this.teamDescription === this.team.teamDescription
      ) {
        return true;
      }
      return false;
    },
  },
  methods: {
    updateFields(key, value) {
      this[key] = value.trim();
    },
    cancelModal() {
      this.$emit('cancel');
    },
    editTeam() {
      if (!this.isDisabled) {
        this.$emit('editTeam', this.teamName, this.teamDescription);
      }
    },
  },
};
</script>

<style scoped>

</style>
